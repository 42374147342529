import React, {useState} from 'react';
import * as Queries from './Queries';
import { useQuery } from "@apollo/client";

import Loading from 'components/Loading/Page';
import Error from 'components/Error/Page';
import MaskPrice from 'functions/mask/price';

import Modal from './Modal';
import { Col, Row, Layout, Table, Typography, Button, Drawer} from 'antd';

import {statuses} from './Misc';
import { render } from 'less';

const { Content } = Layout;
const { Title } = Typography;



function Services() {

	  const { loading, data, error, refetch } = useQuery(Queries.SERVICES, {
	  	fetchPolicy: 'no-cache'
	  });

	  const [modal, setModal] = useState({
	  	visible: false,
	  	data: null
	  });


	  const columns = [
	    {
	      title: 'Nome',
	      dataIndex: 'name',
	      key: 'name',
	    },
	    {
	      title: 'Valor',
	      dataIndex: 'price',
	      key: 'price',
	      render: (val) => {
	      	return process?.env?.REACT_APP_SERVICES_VALUES !== 'off' ? MaskPrice(val) : '';
	      }
	    },
	    {
	      title: 'Descrição',
	      dataIndex: 'description',
	      key: 'description',
	    },
		 {
	      title: 'Ordem',
	      dataIndex: 'order',
	      key: 'order',
			render: (value) => value ?? "-"
	    },
	    {
	    	title: 'Status',
	    	dataIndex: 'status',
	      	key: 'status',
	      	render: (status) => {
	      		return statuses[status];
	      	}
	    },
	    {
	      title: 'Ações',
	      dataIndex: '',
	      key: 'x',
	      render: (value, row) => {
	      	return <Button type="link"
	      	onClick={() => {
	      		setModal(state => {
	      			return {
	      				visible: true,
	      				data: row
	      			}
	      		});
	      	}}
	      	>
	      	  Editar
	      	</Button>
	      },
	    },
	  ];

	  return (
	   		  		<Layout className={'page-wrapper'}>
	   			  		<Content className="site-layout-background">

	   			  		  <div className="page-title">
	   			  		  	<Title>Serviços</Title>
	   			  		  	 <Button type="primary" onClick={() => {
	   			  		  	 	setModal(state => {
	   			  		  	 		return {
	   			  		  	 			visible: true,
	   			  		  	 			data: {}
	   			  		  	 		}
	   			  		  	 	})
	   			  		  	 }}>Novo Serviço</Button>
	   			  		  </div>

	   			  		  <div className="dashboard">

	   			  		  	{loading ? (
	   			  		  		<Loading/>
	   			  		  	) : null}


	   			  		  	{
	   			  		  		(!loading && !error && data) ? (

	   			  		  			<div>
	   			  		  				<Table 
	   			  		  					rowKey="_id" 
	   			  		  					dataSource={data.Services.length ? data.Services : []}
	   			  		  					columns={columns} 
	   			  		  				/>
	   			  		  				<Modal
	   			  		  					visible={modal.visible}
	   			  		  					data={modal.data}
	   			  		  					refetch={refetch}
	   			  		  					close={() => {
	   			  		  						setModal(state => {
	   			  		  							return {
	   			  		  								visible: false,
	   			  		  								data: null
	   			  		  							}
	   			  		  						})
	   			  		  					}}
	   			  		  				/>
	   			  		  			</div>

	   			  		  		) : null
	   			  		  	}

	   			  		  	{
	   			  		  		(!loading && error) ? (
	   			  		  			<Error
	   			  		  				refetch={() => refetch()}
	   			  		  			/>
	   			  		  		) : null
	   			  		  	}

	   			  		  	
	   			  		  </div>
	   			  		</Content>
	   		  		</Layout>
	  );
}

export default Services;
