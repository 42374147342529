import { gql } from '@apollo/client';

export const SERVICES = gql`
query {
  Services {
    _id,
    name,
    price,
    description,
    status,
    order
  }
}
`;


export const SAVE_SERVICE = gql`
mutation Service($input:ServiceInput!, $id: String) {
  Service(input:$input, id: $id) {
  	_id,
    name,
    price,
    description,
    status,
    order
  }
}
`;
