import React, { useEffect } from "react";
import {
	Modal,
	Form,
	Input,
	notification,
	Upload,
	Button,
	Select,
	Radio,
   Spin
} from "antd";
import InputNumberCurrency from "components/InputNumber";
import { useImmer } from "use-immer";
import { useMutation, useLazyQuery, gql } from "@apollo/client";
import { INVOICE_REQUEST } from "./Queries";
import Swal from "sweetalert2";
import {
	UploadOutlined,
	FileDoneOutlined,
	FilePdfFilled,
} from "@ant-design/icons";
import UserSearch from "components/UserSearch";
import MaskedInputCPFCNPJ from "components/InputCPFCNPJ";

const { TextArea } = Input;

const ServiceModal = ({ visible, data, close, refetch }) => {
	const [state, setState] = useImmer({});
	const [save, { loading }] = useMutation(INVOICE_REQUEST);

	const ErrorDialog = (message) => {
		return Swal.fire({
			title: "Erro",
			text: message,
			icon: "error",
		});
	};

	const [loadCTN, ctn] = useLazyQuery(
		gql`
		{
			InvoiceCTN {
				id
				text
			}
		},
	`,
		{
			fetchPolicy: "no-cache",
		}
	);

	const onClose = () => {
		setState({});
		close();
	};

	useEffect(() => {
		if (data) {
			setState({
				...data,
			});
		}
		if (visible) {
			loadCTN();
		}
	}, [visible]);

	const proceed = async () => {
		if (!state.invoice_type)
			return ErrorDialog("Por favor, selecione um tipo de nota.");
		// if (!state.taker)
		//    return ErrorDialog("Por favor, informe um tomador.");
		if (!state.user) return ErrorDialog("Por favor, selecione um usuário.");
		// if (!state.amount)
		//    return ErrorDialog("Por favor, informe um valor.");
		// if (!state.description)
		//    return ErrorDialog("Por favor, informe uma descrição.");
		if (!state.status) return ErrorDialog("Por favor, informe um status.");
		if (state.invoice_type === "service" && !state.service_code)
			return ErrorDialog(
				"Por favor, informe um Código de Tributação Nacional."
			);

		try {
			console.log("state", state);

			const {
				_id,
				business_id,
				createdAt,
				business_name,
				finishedAt,
				fileURL,
				job_uuid,
				user_name,
				partner_company,
				...input
			} = state;

			let vars = {
				input: {
					...input,
				},
				id: _id || null,
			};


			let res = await save({
				variables: vars,
			});

			if (res?.data?.Invoice?._id) {
				notification.success({
					message: "Sucesso",
					description: "Nota Fiscal foi criada com sucesso!",
				});
				close();
				setState({});
				refetch();
			} else {
				ErrorDialog("Não foi possível enviar a solicitação no momento.");
			}
		} catch (error) {
			return ErrorDialog(
				error?.message || "Não foi possível enviar a solicitação no momento."
			);
		}
	};

	console.log(state.business_name);

	return (
		<Modal
			title="Criar Nota Fiscal"
			visible={visible}
			onOk={proceed}
			onCancel={onClose}
			footer={[
				<Button key="back" onClick={onClose} disabled={loading}>
					Cancelar
				</Button>,
				<Button
					type="primary"
					loading={loading}
					disabled={loading}
					onClick={proceed}
				>
					Salvar
				</Button>,
			]}
		>
			<Form layout={"vertical"}>
				<Form.Item label="Tipo de Nota">
					<Radio.Group
						onChange={(e) => {
							setState((d) => {
								d.invoice_type = e.target.value;
							});
						}}
						value={state?.invoice_type}
					>
						<Radio value={"service"}>Serviço</Radio>
						<Radio value={"product"}>Produtos</Radio>
					</Radio.Group>
				</Form.Item>

				{visible && (
					<Form.Item label="Usuário">
						<UserSearch
							style={{ width: "100%" }}
							value={state?.user_name}
							onChange={(value, data) => {
								console.log(value, data);

								let user_name = data.name;
								setState((d) => {
									d.user = value;
									d.user_name = user_name;
								});
							}}
						/>
					</Form.Item>
				)}

				<Form.Item label="Tomador">
					<Input
						value={state?.taker || null}
						onChange={(e) => {
							const { value } = e.target;
							setState((d) => {
								d.taker = value;
							});
						}}
					/>
				</Form.Item>

				{visible && (
					<Form.Item label="CPF / CNPJ">
						<MaskedInputCPFCNPJ
							value={state?.cpfcnpj || null}
							onChange={(e) => {
								const { value } = e.target;
								setState((d) => {
									d.cpfcnpj = value;
								});
							}}
						/>
					</Form.Item>
				)}

				<Form.Item label="Valor">
					<InputNumberCurrency
						style={{ width: "100%" }}
						value={state?.amount || 0}
						onChange={(value) => {
							setState((d) => {
								d.amount = value;
							});
						}}
					/>
				</Form.Item>

				{state?.invoice_type === "service" ? (
					<Form.Item label="Código de Tributação Nacional">
						{ctn?.loading ? (
							<Spin />
						) : (
							<Select
								showSearch
								filterOption={(inputValue, option) => {
									return (
										option.label
											.toLowerCase()
											.indexOf(inputValue.toLowerCase()) > -1
									);
								}}
								onChange={(e) => {
									setState((d) => {
										d.service_code = e;
									});
								}}
								value={state?.service_code || null}
								options={
									ctn?.data?.InvoiceCTN?.length
										? ctn?.data?.InvoiceCTN.map((item) => {
												return {
													label: item.text,
													value: item.id,
												};
										  })
										: []
								}
							/>
						)}
					</Form.Item>
				) : null}

				<Form.Item label="Descrição">
					<TextArea
						rows={8}
						value={state.description || null}
						onChange={(e) => {
							const { value } = e.target;
							setState((d) => {
								d.description = value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label="Observações">
					<TextArea
						rows={4}
						value={state.notes || null}
						onChange={(e) => {
							const { value } = e.target;
							setState((d) => {
								d.notes = value;
							});
						}}
					/>
				</Form.Item>

				<Form.Item label={"Status"}>
					<Select
						value={state?.status || ""}
						onChange={(e) => {
							setState((d) => {
								d.status = e;
							});
						}}
					>
						<Select.Option value="error">Erro</Select.Option>
						<Select.Option value="pending">Aguardando</Select.Option>
						<Select.Option value="done">Finalizado</Select.Option>
						<Select.Option value="cancelled">Cancelado</Select.Option>
												<Select.Option value="sending">Gerando</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item label={"Arquivo"}>
					<Upload
						maxCount={1}
						accept={".pdf,.gif,.jpg,.jpeg,.png"}
						beforeUpload={() => false}
						onChange={(e) => {
							setState((d) => {
								d.file = e.file;
							});
						}}
					>
						<Button icon={<UploadOutlined />}>Selecionar arquivo</Button>
					</Upload>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ServiceModal;
